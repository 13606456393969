.modalInnerContainer {
  height: 300px;
  background-color: white;
  z-index: 103;
  bottom: 0;
  position: fixed;
  width: 100%;
  max-width: 425px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
}

.title {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  line-height: 24px;
  margin-bottom: 30px;
}

.subtitle {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: #333333;

}

.input {
  border-width: 0 0 1px;
  width: 100%;
  padding: 4px 0 4px;
  margin: 0;
  border-color: #E0E0E0;
  background-color: white;
  color: black;
  font-size: 15px;
}

.input:focus {
  outline: none;
}

.submitButton {
  height: 40px;
  background-color: #3F9BFC;
  margin-top: 30px;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: white;
  font-weight: 700;
  border: none;
  cursor: pointer;
}


.editButton {
  height: 40px;
  background-color: #3F9BFC;
  margin-block: 2%;
  border-radius: 5px;
  /* width: 10%; */
  margin-inline: 0.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: white;
  font-weight: 700;
  border: none;
  cursor: pointer;
}